import {Alert, Button, Form, Input, InputNumber, Modal, notification, Select, Tooltip} from "antd";
import {useEffect, useState} from "react";
import {useEditNetwork} from "./hooks/useEditNetwork";
import {Network} from "../../../../../../models/Network";
import {Encryption} from "../../../../../../enums/Encryption";
import {useNetworkDevices} from "../../../device/hooks/useNetworkDevices";
import {HubduoMode} from "../../../../../../enums/HubduoMode";
import {HubduoModeTooltip} from "../../../../../../enums/HubduoModeTooltip";
const { Option } = Select;

type EditNetworkProps = {
    isModalVisible: boolean;
    setIsModalVisible: any;
    network: Network;
}

export function EditNetwork({network, isModalVisible, setIsModalVisible}: EditNetworkProps) {
    const {submitEditNetwork, isSuccessEditNetwork, isLoading, isError, error} = useEditNetwork();
    const {devices, refetch} = useNetworkDevices(network.id, null);
    const [hasBridgeDeviceInNetwork, setHasBridgeDeviceInNetwork] = useState(false);
    const [form] = Form.useForm();
    const [submittable, setSubmittable] = useState(false);

    let validationError;
    const potentialError = error as any;
    if (potentialError?.response?.status === 422) {
        validationError = potentialError?.response?.data;
    }

    const values = Form.useWatch([], form);

    useEffect(() => {
        devices?.forEach(device => {
            if (device.type === 'bridge' || device.model?.toLowerCase().includes('bridge')) {
                setHasBridgeDeviceInNetwork(true);
            }
        })
    }, [devices])

    useEffect(() => {
        form.validateFields().then(
            () => {
                setSubmittable(true);
            },
            () => {
                setSubmittable(false);
            },
        );
    }, [values]);

    const handleOk = () => {
        if (submittable) {
            form.submit();
        }
    }

    const handleSubmit = async (data: any) => {
        const updatedRow = data as Network;
        if (network) {
            const record: Network = {
                ...network,
                SSID: updatedRow.SSID,
                channel: updatedRow.channel,
                encryption: updatedRow.encryption,
                wpa_key: updatedRow.wpa_key,
                bridge_channel_1: updatedRow.bridge_channel_1 ? updatedRow.bridge_channel_1 : network.bridge_channel_1,
                channel_5g: updatedRow.channel_5g ?? network.channel_5g,
                hubduo_mode: updatedRow.hubduo_mode ?? network.hubduo_mode
            }
            submitEditNetwork(record);
        }
    }

    const handleCancel = () => {
        setIsModalVisible(false);
    }

    const charValidator = (rule: any, value: string, callback: any) => {
        const pattern = /[$&?<>*|\/\\'“"]/;
        if (pattern.test(value)) {
            callback('Value must not contain the following characters: $, &, ?, <, >, *, | , /, \\, \', “');
        } else {
            callback();
        }
    };

    const formItems = [
        {
            name: "SSID",
            label: "SSID (Network name)",
            value: network?.SSID,
            rules: [
                {
                    max: 32,
                    message: 'Maximum number of characters is 32'
                },
                { required: true, message: 'Please input the SSID (Network name)' },
                { validator: charValidator }
            ]
        },
        {
            name: "wpa_key",
            label: "Encryption passkey",
            value: network?.wpa_key,
            rules: [
                {
                    max: 32,
                    message: 'Maximum number of characters is 32'
                },
                { min: 8, message: 'Encryption passkey must be minimum 8 characters long.' },
                { required: true, message: 'Please input the Encryption passkey' },
                { validator: charValidator }
            ]
        },
        {
            name: "channel",
            label: "WiFi Channel",
            value: network?.channel,
            rules: [{ required: true, message: 'Please input the WiFi Channel' }]
        },
        {
            name: "encryption",
            label: "Encryption",
            value: network?.encryption,
            rules: [{ required: true, message: 'Please input the Encryption' }]
        },
    ];

    useEffect(() => {
        if (isSuccessEditNetwork) {
            setIsModalVisible(false);
            notification.success({
                message: "You've successfully updated the network"
            });
        }
    }, [isSuccessEditNetwork])


    const channels = [...Array(11)].map((e, index) => {
            return {
                value: index+1,
                label: (index+1).toString()
            };
        }
    );

    const bridgeChannels = [149, 153, 157, 161, 165].map(channel => {
            return {
                value: channel,
                label: channel.toString()
            };
        }
    );

    const channels5G = [149, 153, 157, 161].map(channel => {
            return {
                value: channel,
                label: channel.toString()
            };
        }
    );

    const encryptions = [...Array(3)].map((e, index) => {
            return {
                value: index,
                label: Encryption[index]
            };
        }
    );

    const hubduoModes = [...Array(4)].map((e, index) => {
            return {
                value: index,
                label: HubduoMode[index],
                tooltip: index > 0 ? HubduoModeTooltip[index] : ''
            };
        }
    );


    return (
        <Modal
            title="Edit Network"
            open={isModalVisible}
            okButtonProps={{
                loading: isLoading,
                style: { background: '#05a955', borderColor: '#05a955' }
            }}
            bodyStyle={{
                height: "auto",
                overflowY: "auto"
            }}
            onOk={handleOk}
            onCancel={handleCancel}
        >
            {isError}
            {isError && !validationError && (error as any) && (
                <Alert
                    style={{marginBottom: 10}}
                    message={(error as any)?.response?.data?.detail || "An error occurred"}
                    type="error"
                />
            )}
            {isError && validationError && (validationError.detail.map((e: any, idx: number) =>
                        <Alert
                            key={idx}
                            style={{marginBottom: 10}}
                            type="error"
                            message={
                                <>
                <span style={{fontWeight: "bold"}}>
                  {e.loc[1]}:
                </span> {" "} {e.msg}
                                </>
                            }
                        />
                )
            )}
            <Form
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
            >
                {formItems.map((fi, idx) => {
                    return (
                        <Form.Item
                            initialValue={fi.value}
                            name={fi.name}
                            label={fi.label}
                            key={idx}
                            rules={fi.rules}
                        >
                            {(fi.name !== 'channel' && fi.name !== 'encryption') ? <Input /> :
                                <Select
                                    options={fi.name === 'channel' ? channels : encryptions}
                                />
                            }
                        </Form.Item>
                    )}
                )}
                {hasBridgeDeviceInNetwork &&
                    <Form.Item
                        initialValue={network?.bridge_channel_1}
                        name={"bridge_channel_1"}
                        label={"Bridge Channel"}
                    >
                        <Select
                            options={bridgeChannels}
                        />
                    </Form.Item>
                }
                {network.has_hubduo_device &&
                    <>
                        <Form.Item
                            initialValue={network?.channel_5g}
                            name={"channel_5g"}
                            label={"5GHz. Channel"}
                        >
                            <Select
                                options={channels5G}
                            />
                        </Form.Item>
                        <Form.Item
                            initialValue={network?.hubduo_mode}
                            name={"hubduo_mode"}
                            label={"HubDuo Mode"}
                        >
                            <Select>
                                {hubduoModes.map(mode => (
                                    <Option key={mode.value} value={mode.value}>
                                        <Tooltip title={mode.tooltip}>
                                            <span style={{paddingRight: 300}}>
                                                {mode.label}
                                            </span>
                                        </Tooltip>
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </>
                }
                <Form.Item style={{display: "none"}}>
                    <Button htmlType="submit" />
                </Form.Item>
            </Form>
        </Modal>
    )
}
