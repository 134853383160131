import { useAppSelector } from "../../../../../redux/hooks";
import {selectLoggedInUser, selectUserID} from "../../../../../redux/selectors/authSelector";
import React, { useEffect, useState } from "react";
import { useNetworkColumns } from "../hooks/useNetworkColumns";
import {Button, Col, Form, Input, notification, Popover, Row, Select, Table, Tag, Tooltip, Typography} from "antd";
import { useNetwork } from "../hooks/useNetwork";
import {EditOutlined, PlusCircleOutlined, SearchOutlined, FullscreenOutlined} from "@ant-design/icons";
import { Network } from "../../../../../models/Network";
import { DeviceTable } from "../../device/DeviceTable";
import { EditNetwork } from "../components/edit-network/EditNetwork";
import { Encryption } from "../../../../../enums/Encryption";
import "./NetworkTable.css";
import { DeviceSearchOptions } from "../../../../../services/DeviceService";
import { useEditNetwork } from "../components/edit-network/hooks/useEditNetwork";
import { ManagePermissions } from "../components/manage-permissions/ManagePermissions";
import {useResendVerificationEmail} from "./hooks/useResendVerificationEmail";
import {ResendConfirmationEmailRequest} from "../../../../../models/ResendConfirmationEmailRequest";
import {HubduoMode} from "../../../../../enums/HubduoMode";
import {HubduoModeTooltip} from "../../../../../enums/HubduoModeTooltip";

type TableProps = {
  networks: Network[];
  columns: any[];
  expandRow: (expanded: boolean, record: Network) => void;
  expandedNetworks: number[];
  querySearchParams: DeviceSearchOptions;
};

function TableNetworks({
  networks,
  columns,
  expandRow,
  expandedNetworks,
  querySearchParams
}: TableProps) {
  return (
    <Table
      bordered
      className={'networks-table'}
      dataSource={networks}
      columns={columns as any}
      rowClassName="editable-row"
      rowKey="id"
      scroll={{ x: 500 }}
      onExpand={expandRow}
      pagination={false}
      expandable={{
        expandedRowRender: (record: Network) => (
          <DeviceTable network={record} querySearchParams={querySearchParams} />
        ),
        expandRowByClick: false,
        expandedRowKeys: expandedNetworks,
      }}
    />
  );
}

export function NetworkTable() {
  const userId = useAppSelector(selectUserID);
  const loggedInUser = useAppSelector(selectLoggedInUser);
  const [columns, setColumns] = useState<any[]>([]);
  const [clickedOnResendEmail, setClickedOnResendEmail] = useState(false);
  const cols = useNetworkColumns();
  const { submitResendVerificationEmail, resendVerificationEmailIsSuccess, resendVerificationEmailError } = useResendVerificationEmail();

  const myNetworkColumns = [
    ...columns,
      {
        title: "Actions",
        dataIndex: "edit",
        width: "5%",
        align: 'center',
        render: (_: any, record: Network) => {
          return (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Popover content={'Edit'}>
                  <Typography.Link onClick={() => edit(record)}>
                    <EditOutlined twoToneColor="#7a7a7a" style={{ fontSize: "20px", color: '#7a7a7a', marginRight: 20 }} />
                  </Typography.Link>
                </Popover>
                <Popover content={'Manage Permissions'}>
                  <Typography.Link onClick={() => managePermissions(record)}>
                    <FullscreenOutlined twoToneColor="#7a7a7a" style={{ fontSize: "20px", color: '#7a7a7a' }} />
                  </Typography.Link>
                </Popover>
              </div>
          );
        },
      }
  ]

  const otherNetworkColumns = [
    {
      title: "Source User",
      dataIndex: "source_user_username",
      editable: true,
      inputType: 'text',
      width: '15%',
      align: 'center',
    },
    ...columns,
    {
      title: "Actions",
      dataIndex: "edit",
      width: "5%",
      align: 'center',
      render: (_: any, record: Network) => {
        return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Popover content={'Edit'}>
                <Typography.Link onClick={() => edit(record)}>
                  <EditOutlined twoToneColor="#7a7a7a" style={{ fontSize: "20px", color: '#7a7a7a', marginRight: 20 }} />
                </Typography.Link>
              </Popover>
              <Popover content={'Manage Permissions'}>
                <Typography.Link onClick={() => managePermissions(record)}>
                  <FullscreenOutlined twoToneColor="#7a7a7a" style={{ fontSize: "20px", color: '#7a7a7a' }} />
                </Typography.Link>
              </Popover>
            </div>
        );
      },
    }
  ]
  const [querySearchParams, setQuerySearchParams] = useState<DeviceSearchOptions | any>(
      {} as DeviceSearchOptions
  );
  const { networks, isLoading, refetch } = useNetwork(userId, querySearchParams);
  const {submitEditNetwork, isSuccessEditNetwork} = useEditNetwork();
  const [form] = Form.useForm();
  const [expandedNetworks, setExpandedNetworks] = useState<number[]>([]);
  const [networkForEdit, setNetworkForEdit] = useState<Network>();
  const [editNetworkModalVisible, setEditNetworkModalVisible] = useState(false);
  const [managePermissionsModalVisible, setManagePermissionsModalVisible] = useState(false);
  const [networkForManagePermissions, setNetworkForManagePermissions] = useState<Network>();
  const [myNetworks, setMyNetworks] = useState<Network[]>([]);
  const [networksUnderControl, setNetworksUnderControl] = useState<Network[]>(
    []
  );

  const edit = (record: Network) => {
    setEditNetworkModalVisible(true);
    setNetworkForEdit(record);
  };

  const managePermissions = (record: Network) => {
    setManagePermissionsModalVisible(true);
    setNetworkForManagePermissions(record);
  };

  const handleSubmit = (data: any) => {
    const queryParams = Object.keys(data)
        .filter(k => data[k])
        .reduce(
            (prev, curr) => (
                {
                  ...prev,
                  [curr]: data[curr]
                }
            ),
            {}
        );
    setQuerySearchParams(queryParams);
  }

  const change5GChannel = (channel_5g: string, record: Network) => {
    record.channel_5g = parseInt(channel_5g);
    submitEditNetwork(record);
  }

  const set5GChannelColumns = () => {
    setColumns([
      ...cols,
      {
        title: "HubDuo Mode",
        dataIndex: "hubduo_mode",
        editable: true,
        inputType: 'number',
        width: '10%',
        align: 'center',
        render: (hubduo_mode: number, record: Network) => {
          const tooltip = HubduoModeTooltip[hubduo_mode];
          if (!record.has_hubduo_device) {
            return '';
          }
          return (
              <Tooltip title={tooltip}>
                <span>{HubduoMode[hubduo_mode]}</span>
              </Tooltip>
          )
        },
      },
      {
        title: "5GHz. Channel",
        dataIndex: "channel_5g",
        editable: true,
        inputType: 'number',
        width: '10%',
        align: 'center',
        render: (channel_5g: number, record: Network) => {
          if (!record.has_hubduo_device) {
            return '';
          }
          return channel_5g;
        },
      },
      {
        title: "Encryption",
        dataIndex: "encryption",
        editable: true,
        inputType: "text",
        width: "20%",
        align: "center",
        render: (encryption: number) => <span>{Encryption[encryption]}</span>,
      },
    ]);
  }

  useEffect(() => {
    if (isSuccessEditNetwork) {
      set5GChannelColumns();
    }
  }, [isSuccessEditNetwork])

  useEffect(() => {
    const intervalId = setInterval(() => {
      refetch(); // Fetch data every minute
    }, 60000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    refetch();
  }, [querySearchParams])

  useEffect(() => {
    if (resendVerificationEmailIsSuccess) {
      notification.success({
        message: "Verification email resent!",
      });
    }
  }, [resendVerificationEmailIsSuccess]);

  useEffect(() => {
    if (networks && networks.length) {
      console.log(networks)
      setMyNetworks(
        networks.filter((network: Network) => network.source_user_id === userId)
      );
      setNetworksUnderControl(
        networks.filter((network: Network) => network.source_user_id !== userId)
      );
      const hasHubduoDevice = !!networks.find(net => net.has_hubduo_device);
      if (hasHubduoDevice) {
        set5GChannelColumns();
      } else {
        setColumns([
          ...cols,
          {
            title: "Encryption",
            dataIndex: "encryption",
            editable: true,
            inputType: "text",
            width: "20%",
            align: "center",
            render: (encryption: number) => <span>{Encryption[encryption]}</span>,
          },
        ]);
      }
    } else {
      setMyNetworks([]);
      setNetworksUnderControl([]);
    }
  }, [networks]);

  useEffect(() => {
    if (myNetworks.length) {
      setExpandedNetworks(
        !expandedNetworks.length ? [myNetworks[0].id] : expandedNetworks
      );
    }
  }, [myNetworks]);

  useEffect(() => {
    if (networksUnderControl.length && (querySearchParams?.mac_address || querySearchParams?.device_name)) {
      setExpandedNetworks(
          !expandedNetworks.length ? [networksUnderControl[0].id] : expandedNetworks
      );
    }
  }, [networksUnderControl]);

  const expandRow = (expanded: boolean, record: Network) => {
    const network = expandedNetworks.find((nt) => nt === record.id);
    if (!network) {
      setExpandedNetworks([...expandedNetworks, record.id]);
    } else {
      setExpandedNetworks([
        ...expandedNetworks.filter((nt) => nt !== record.id),
      ]);
    }
  };

  const onVerifyEmailAddressClick = () => {
    if (loggedInUser) {
      const resendConfirmationEmailRequest: ResendConfirmationEmailRequest = {
        confirmation_token: loggedInUser.confirmation_token,
        email: loggedInUser.email
      }
      if (!clickedOnResendEmail) {
        submitResendVerificationEmail(resendConfirmationEmailRequest);
        setClickedOnResendEmail(true);
      }
    }
  }

  return (
    <>
      <div style={{marginTop: 25}}>
        <Form
            layout="horizontal"
            name="basic"
            onFinish={handleSubmit}
            autoComplete="off"
        >
          {loggedInUser?.confirmation_token &&
            <Row>
              <Col xl={24} sm={24} style={{marginBottom: 20}}>
                <Tag style={{ padding: 15, fontSize: 18, width: '100%', textAlign: 'center', overflow: 'auto' }} color="gold">
                  Your email address is not verified. Click
                  <span onClick={() => onVerifyEmailAddressClick()} style={{ fontWeight: 'bold', marginLeft: 5, marginRight: 5, cursor: 'pointer' }}>
                    here
                  </span>
                  to resend a verification email.
                </Tag>
              </Col>
            </Row>
          }
          <Row>
            <Col xs={24} sm={24} lg={10}>
              <h1>My Networks</h1>
            </Col>
            <Col xs={24} sm={12} lg={4} style={{margin: 'auto'}}>
              <Form.Item
                  label="MAC"
                  name="mac_address"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} lg={4} style={{margin: 'auto'}}>
              <Form.Item
                  style={{marginLeft: 10}}
                  label="Device Name"
                  name="device_name"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} lg={4}>
              <Form.Item>
                <Button
                    style={{backgroundColor: '#05a955', borderColor: '#05a955'}}
                    type="primary"
                    icon={<SearchOutlined />}
                    block
                    htmlType="submit">
                  Search
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      <Form form={form} component={false}>
        <TableNetworks
          networks={myNetworks}
          columns={myNetworkColumns}
          expandedNetworks={expandedNetworks}
          expandRow={expandRow}
          querySearchParams={querySearchParams}
        />
        {networksUnderControl.length ?
          <>
            <h1 style={{ marginTop: 8 }}>Other Networks You Control</h1>
            <TableNetworks
                networks={networksUnderControl}
                columns={otherNetworkColumns}
                expandedNetworks={expandedNetworks}
                expandRow={expandRow}
                querySearchParams={querySearchParams}
            />
          </> : <></>
        }
        {editNetworkModalVisible && networkForEdit && (
          <EditNetwork
            network={networkForEdit}
            isModalVisible={editNetworkModalVisible}
            setIsModalVisible={setEditNetworkModalVisible}
          />
        )}
        {managePermissionsModalVisible && networkForManagePermissions && (
            <ManagePermissions
                network={networkForManagePermissions}
                isModalVisible={managePermissionsModalVisible}
                setIsModalVisible={setManagePermissionsModalVisible}
                refetchNetworks={refetch}
            />
        )}
      </Form>
    </>
  );
}
